import React from "react";

function ApplyButton({ link, className }) {

  return (
    <div
      className={`bloom-flex bloom-items-center bloom-justify-center ${className}`}
    >
      <a
        className={`bloom-bg-green bloom-border-none bloom-text-white bloom-mr-3 bloom-px-20 bloom-py-5 bloom-leading-tight bloom-font-bold bloom-shadow-md hover:bloom-bg-white hover:bloom-text-green`}
        target="_blank"
        href={link}
      >
        Apply here
      </a>
    </div>
  );
}

export default ApplyButton;
